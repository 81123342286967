import React from "react";
import logo from "./logo.svg";
import "./App.css";
import AnimationCircle from "./AnimationCircle";
import { useSpring, animated } from "react-spring";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import {
    Heading,
    Image,
    Button,
    Stack,
    HStack,
    IconButton,
    Center,
    Text,
    LinkBox,
    LinkOverlay,
    Link,
} from "@chakra-ui/react";
import {
    FaGithub,
    FaTwitter,
    FaTelegram,
    FaLinkedin,
    FaMedium,
} from "react-icons/fa";

const theme = extendTheme({
    fonts: {
        heading: "'Knockout', sans-serif",
        body: "'Inter', sans-serif",
    },
});

function App() {
    const logoSpring = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        delay: 200,
    });
    const headingSpring = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        delay: 400,
    });
    const textSpring = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        delay: 600,
    });
    const linkBoxesSpring = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        delay: 1000,
    });
    const buttonSpring = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        delay: 1200,
    });
    const iconsSpring = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        delay: 1400,
    });

    return (
        <ChakraProvider theme={theme}>
            <Center
                bg="black"
                color="white"
                flexDirection="column"
                textAlign="center"
            >
                <animated.div style={logoSpring}>
                    <Image src={logo} alt="Axone logo" width="250px" height="200px" mb={4}/>
                </animated.div>
                <animated.div style={headingSpring}>
                    <Heading
                        as="h1"
                        fontSize={{
                            base: "50px",
                            sm: "80px",
                            md: "100px",
                            lg: "120px",
                            xl: "140px",
                        }}
                        lineHeight={{
                            base: "40px",
                            sm: "60px",
                            md: "70px",
                            lg: "90px",
                            xl: "100px",
                        }}
                        letterSpacing="0.5px"
                        className="stretched-text"
                        mt={4}
                    >
                        The orchestration<br></br> layer for AI
                    </Heading>
                </animated.div>
                <animated.div style={textSpring}>
                    <Text my={4} px={4} fontSize={"20px"} className="baseline">
                        Axone is a layer-1 designed for collaborative AI
                        training, governance and monetization at scale
                    </Text>
                </animated.div>
                <AnimationCircle />
                <animated.div style={textSpring}>
                    <Text
                        my={4}
                        color="#FF6100"
                        className="coming-soon baseline"
                    >
                        Coming Soon
                    </Text>
                </animated.div>

                <animated.div style={linkBoxesSpring}>
                    <Stack
                        spacing={8}
                        my={4}
                        className="link-boxes"
                        direction={{ base: "column", md: "row" }}
                        align="center"
                        justify="center"
                    >
                        <LinkBox
                            as="article"
                            bgColor="transparent"
                            w={{ base: "95%", md: "30%" }}
                            h="220px"
                            p={4}
                            mx={4}
                            border="1px solid #FF6100"
                            borderRadius="md"
                            _hover={{ bg: "#FF6100", color: "white" }}
                        >
                            <LinkOverlay
                                href="https://docs.axone.xyz/whitepaper/abstract"
                                isExternal
                            ></LinkOverlay>
                            <Heading as="h2" size="lg" className="title-card">
                                WhitePaper
                            </Heading>
                            <Text
                                size="lg"
                                fontSize={18}
                                className="subtitle-card"
                            >
                                Our thesis
                            </Text>
                            <Text mt={4} fontSize={18} className="body-card">
                                What is Axone? What is it for? What is the
                                technical architecture?
                            </Text>
                        </LinkBox>
                        <LinkBox
                            as="article"
                            bgColor="transparent"
                            w={{ base: "95%", md: "30%" }}
                            h="220px"
                            p={4}
                            mx={4}
                            border="1px solid #007AFF"
                            borderRadius="md"
                            _hover={{ bg: "#007AFF", color: "white" }}
                        >
                            <LinkOverlay
                                href="https://docs.axone.xyz/technical-documentation/overview"
                                isExternal
                            ></LinkOverlay>
                            <Heading as="h2" size="lg" className="title-card">
                                Docs
                            </Heading>
                            <Text
                                size="lg"
                                fontSize={18}
                                className="subtitle-card"
                            >
                                Let's Build
                            </Text>
                            <Text fontSize={18} mt={4} className="body-card">
                                Delve into the core technical concepts behind
                                the Axone Protocol.
                            </Text>
                        </LinkBox>

                        <LinkBox
                            as="article"
                            bgColor="transparent"
                            w={{ base: "95%", md: "30%" }}
                            h="220px"
                            p={4}
                            mx={4}
                            border="1px solid #FF6100"
                            borderRadius="md"
                            _hover={{ bg: "#FF6100", color: "white" }}
                        >
                            <LinkOverlay
                                href="https://github.com/orgs/axone-protocol/repositories"
                                isExternal
                            ></LinkOverlay>
                            <Heading as="h2" size="lg" className="title-card">
                                GitHub repos
                            </Heading>
                            <Text
                                size="lg"
                                fontSize={18}
                                className="subtitle-card"
                            >
                                Commited to open-source
                            </Text>
                            <Text fontSize={18} mt={4} className="body-card">
                                Where the magic happens
                            </Text>
                        </LinkBox>
                    </Stack>
                </animated.div>
                <animated.div style={buttonSpring}>
                    <Link
                        href="mailto:hello@axone.xyz"
                        isExternal
                        _hover={{ textDecoration: "none" }}
                    >
                        <Button
                            bg="#FF6100"
                            color="white"
                            size="lg"
                            my={4}
                            _hover={{ bg: "#007AFF", color: "white" }}
                        >
                            Receive our deck
                        </Button>
                    </Link>
                </animated.div>
                <animated.div style={iconsSpring}>
                    <HStack spacing={4} my={4}>
                        <IconButton
                            isRound={true}
                            bgColor="transparent"
                            color="white"
                            border="1px solid #FF6100"
                            as="a"
                            href="https://x.com/axonexyz"
                            icon={<FaTwitter />}
                            aria-label="Twitter"
                            colorScheme="whiteAlpha"
                            _hover={{ bg: "#FF6100", color: "white" }}
                        />
                        <IconButton
                            isRound={true}
                            bgColor="transparent"
                            color="white"
                            border="1px solid #FF6100"
                            as="a"
                            href="https://t.Me/axonexyz"
                            icon={<FaTelegram />}
                            aria-label="Telegram"
                            fontSize="40px" /* Vous pouvez ajuster la taille ici */
                            boxSize="40px" /* Vous pouvez ajuster la taille ici également */
                            p={2} /* Ajouter du padding si nécessaire */
                            _hover={{ bg: "#FF6100", color: "white" }}
                        />
                        <IconButton
                            isRound={true}
                            bgColor="transparent"
                            color="white"
                            border="1px solid #FF6100"
                            as="a"
                            href="https://www.linkedin.com/company/axone-protocol"
                            icon={<FaLinkedin />}
                            aria-label="LinkedIn"
                            _hover={{ bg: "#FF6100", color: "white" }}
                        />
                        <IconButton
                            isRound={true}
                            bgColor="transparent"
                            color="white"
                            border="1px solid #FF6100"
                            as="a"
                            href="https://github.com/axone-protocol"
                            icon={<FaGithub />}
                            aria-label="GitHub"
                            _hover={{ bg: "#FF6100", color: "white" }}
                        />

                        <IconButton
                            isRound={true}
                            bgColor="transparent"
                            color="white"
                            border="1px solid #FF6100"
                            as="a"
                            href="https://blog.axone.xyz"
                            icon={<FaMedium />}
                            aria-label="Medium"
                            _hover={{ bg: "#FF6100", color: "white" }}
                        />
                    </HStack>
                </animated.div>
                <animated.div style={textSpring}>
                    <Text my={8} fontSize={14}>
                        Axone - 2024 - All rights reserved
                    </Text>
                </animated.div>
            </Center>
        </ChakraProvider>
    );
}

export default App;
