import React from "react";
import { useSpring, animated } from "react-spring";

const AnimationCircle = () => {
    // Utiliser useSpring pour créer des animations de rotation pour chaque calque
    const arrowSpring = useSpring({
        to: { transform: "rotate(360deg)" },
        from: { transform: "rotate(0deg)" },
        loop: true,
        config: { duration: 14000 },
    });
    const orangeCircleSpring = useSpring({
        to: { transform: "rotate(-360deg)" },
        from: { transform: "rotate(0deg)" },
        loop: true,
        config: { duration: 25000 },
    });
    const blueCircleSpring = useSpring({
        to: { transform: "rotate(360deg)" },
        from: { transform: "rotate(0deg)" },
        loop: true,
        config: { duration: 36000 },
    });
    const whiteCircleSpring = useSpring({
        to: { transform: "rotate(-360deg)" },
        from: { transform: "rotate(0deg)" },
        loop: true,
        config: { duration: 27000 },
    });

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1000 1000"
            width="600"
            height="600"
        >
            <defs>
                <radialGradient
                    id="Dégradé_sans_nom_23"
                    cx="499.81"
                    cy="497.35"
                    fx="375.92"
                    fy="616.33"
                    r="314.71"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".14" stopColor="#d05a0f" />
                    <stop offset=".44" stopColor="#8b8185" />
                    <stop offset=".63" stopColor="#0a55ce" />
                    <stop offset="1" stopColor="#020b12" />
                </radialGradient>
            </defs>
            <g id="Bg">
                <polygon
                    points="814.52 413.02 584.14 182.64 269.43 266.97 185.1 581.68 415.48 812.06 730.19 727.73 814.52 413.02"
                    style={{ fill: "url(#Dégradé_sans_nom_23)" }}
                />
                <circle cx="500.5" cy="500.5" r="70.3" />
            </g>
            <animated.g
                id="WhiteCircle"
                style={whiteCircleSpring}
                transform-origin="500.5px 500.5px"
            >
                <circle
                    cx="500.5"
                    cy="500.5"
                    r="227.99"
                    style={{
                        fill: "none",
                        stroke: "#fff",
                        strokeMiterlimit: 10,
                        strokeWidth: 2,
                        strokeDasharray: "10 10",
                    }}
                />
            </animated.g>
            <animated.g
                id="BlueCircle"
                style={blueCircleSpring}
                transform-origin="500.5px 500.5px"
            >
                <circle
                    cx="297.14"
                    cy="651.45"
                    r="101.78"
                    style={{ fill: "#0b30ff" }}
                />
            </animated.g>
            <animated.g
                id="OrangeCircle"
                style={orangeCircleSpring}
                transform-origin="500.5px 500.5px"
            >
                <circle
                    cx="768.4"
                    cy="764.97"
                    r="68.49"
                    style={{ fill: "#d05a0f" }}
                />
            </animated.g>
            <animated.g
                id="Arrow"
                style={arrowSpring}
                transform-origin="500.5px 500.5px"
            >
                <path
                    d="M503.54,911.32c1.67-.08,3.33-.17,4.99-.27"
                    style={{
                        fill: "none",
                        stroke: "#fff",
                        strokeMiterlimit: 10,
                        strokeWidth: 2,
                    }}
                />
                <path
                    d="M518.62,910.37c225.2-17.44,392.79-178.45,397.71-400.31"
                    style={{
                        fill: "none",
                        stroke: "#fff",
                        strokeDasharray: "0 0 10.11 10.11",
                        strokeMiterlimit: 10,
                        strokeWidth: 2,
                    }}
                />
                <path
                    d="M916.41,505c.02-1.66.03-3.33.03-5"
                    style={{
                        fill: "none",
                        stroke: "#fff",
                        strokeMiterlimit: 10,
                        strokeWidth: 2,
                    }}
                />
                <circle
                    cx="581.49"
                    cy="90.38"
                    r="22.54"
                    style={{ fill: "#fff" }}
                />
                <path
                    d="M506.51,79.61c-.17,0-.33,0-.5,0-232.18,0-413.61,178.33-420.27,410.42"
                    style={{
                        fill: "none",
                        stroke: "#fff",
                        strokeMiterlimit: 10,
                        strokeWidth: 2,
                    }}
                />
                <polygon
                    points="506.51 67.84 506.51 90.38 520.14 79.11 506.51 67.84"
                    style={{
                        fill: "none",
                        stroke: "#fff",
                        strokeMiterlimit: 10,
                        strokeWidth: 2,
                    }}
                />
            </animated.g>
        </svg>
    );
};

export default AnimationCircle;
